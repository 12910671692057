import React from "react";
import "./Home.css";
import Me from "../../assets/download-MF_js7aYd-transformed-transformed.jpeg";
import HeaderSocials from "./HeaderSocials";
import ScrollDown from "./ScrollDown";
import Shapes from "./Shapes";

const Home = () => {
  return (
    <section className="home container" id="home">
      <div className="intro flex justify-center flex-col items-center">
        <img src={Me} alt="" className="home__img rounded-full" width="120" />
        <h1 className="home__name">Bhaskar Pandey</h1>
        <span className="home__education">Full-Stack Developer</span>

        <HeaderSocials />

        <a href="#contact" className="btn">
          Contact Me
        </a>

        <ScrollDown />
      </div>

      <Shapes />
    </section>
  );
};

export default Home;
