import React from "react";
import "./Blog.css";

import Image1 from "../../assets/1_s1ldvprg3JZxyu_8uLLFrQ.webp";

const Blog = () => {
  return (
    <section className="blog container section" id="blog">
      <h2 className="section__title">Latest Posts</h2>

      <div className="blog__container grid">
        <div className="blog__card">
          <div className="blog__thumb">
            <a href="https://medium.com/@bhaskar-pandey/mastering-javascript-closures-a-deep-dive-3e347d95aea8">
              <span className="blog__category">Closures</span>
            </a>
            <a href="https://medium.com/@bhaskar-pandey/mastering-javascript-closures-a-deep-dive-3e347d95aea8">
              <img src={Image1} alt="" className="blog__img" />
            </a>
          </div>
          <div className="blog__details">
            <h3 className="blog__title">
              Mastering JavaScript Closures: A Deep Dive ✨
            </h3>
            <div className="blog__meta">
              <span>Jun 12, 2024</span>
              <span className="blog__dot">.</span>
              <span>Bhaskar</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
